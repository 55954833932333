import { Link } from 'react-router-dom';

export function LogoutPage() {
  return <div className="content-container">
  <div className="content-header">
      <h2>Logged Out</h2>
      <div className="content-main">
      <h3>You are now logged out</h3>
      <Link to="/">Home Page</Link>
      </div>
  </div>
</div>

}
