import './header.scss';

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { NavLink } from "react-router-dom";
import { INavigationItem } from "../../interfaces/IUser";
import { useUserAccountQuery } from "../../services/metervueapi";
import { useScreenSizeClass } from "../../utils/media-query";
import UserPanel from "../user-panel/user-panel";
import { Button } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import { useState } from "react";
import { loginRequest } from "../../authConfig";

export function Header() {

    const { instance } = useMsal();
    const screenSizeClass = useScreenSizeClass();
    const [popupVisible, setPopupVisible] = useState(false);
    const { data: user, isLoading } = useUserAccountQuery("");

    function MvMenu() {
        const { inProgress, accounts } = useMsal();

        if (inProgress === "none" && accounts.length) {
            return <UserMvMenu />;
        }
        return <PublicMvMenu />;
    }

    function UserMvMenu() {
        
        let navigation: INavigationItem[] = [{
            text: "Home",
            path: "/",
            cssClass: "home"
        }];

        if (!isLoading) {
            navigation = user?.navigation || navigation;
        }


        return <div className="mv-navigation">{navigation?.map((item) =>
            <NavLink
                to={item.path}
                key={item.path}
                className={({ isActive }) => isActive ? "mv-nav-active" : ""}
                onClick={() => { setPopupVisible(false) }}>{item.text}</NavLink>
        )}</div>;
    }

    function PublicMvMenu() {

      

        let navigation: INavigationItem[] = [{
            text: "Home",
            path: "/",
            cssClass: "home"
        }];

        return <div className="mv-navigation">{navigation?.map((item) =>
            <NavLink
                to={item.path}
                key={item.path}
                className={({ isActive }) => isActive ? "mv-nav-active" : ""}
                onClick={() => { setPopupVisible(false) }}>{item.text}</NavLink>
        )}</div>;
    }

    if (screenSizeClass === 'screen-small') {
        return <div className="mv-header-container">
            <div className="mv-logo"><a href="/" className="header-logo">MeterVue</a></div>
            <div className="mv-menu-button">
                <UnauthenticatedTemplate>
                    <Button onClick={() => instance.loginRedirect(loginRequest)}
                        className="button-info"
                        text="Login"
                        icon="fas fa-sign-in-alt"
                        rtlEnabled={true} />
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <Button
                        className="button-info"
                        text="Menu"
                        icon="fas fa-bars"
                        rtlEnabled={true}
                        onClick={() => setPopupVisible(true)}
                    />

                    <Popup
                        visible={popupVisible}
                        onHiding={() => setPopupVisible(false)}
                        dragEnabled={false}
                        closeOnOutsideClick={true}
                        showCloseButton={true}
                        showTitle={false}
                        title="Menu"
                        container=".dx-viewport"
                        width={360}
                        height={500}
                        fullScreen={true}
                        animation={undefined}
                    >
                        <div className="mv-body">
                            <div className="mv-header-container">
                                <div className="mv-logo"><a href="/" className="header-logo">MeterVue</a></div>
                                <div className="mv-menu-button">
                                    <Button
                                        className="button-info"
                                        text="Menu"
                                        icon="fas fa-times"
                                        rtlEnabled={true}
                                        onClick={() => setPopupVisible(false)}
                                    />
                                </div>
                            </div>

                            <MvMenu />

                            <div className="mv-nav-menu-user"><UserPanel /></div>
                        </div>
                    </Popup>
                </AuthenticatedTemplate>
            </div>
        </div>

    }

    return <>
        <div className="mv-body-header">
            <div className="mv-header-container">
                <div className="mv-logo"><a href={user?.homepage} className="header-logo">MeterVue</a></div>
                <div className="mv-menu-button"><UserPanel /></div>
            </div>
        </div>
        <div className="mv-body-nav">
            <MvMenu />
        </div>
    </>
}