import { useEffect, useState } from 'react';
import DropDownBox, { DropDownOptions } from 'devextreme-react/drop-down-box';
import SelectOrganisationTree from './select-organisation-tree'
import _ from 'lodash';
import { useOrganisationsQuery } from '../../services/metervueapi';

type SelectOrganisationProps = { organisationID?: number, SelectOrganisation_ValueChange: (val: number) => void, width?: number };


export default function SelectOrganisation(props: SelectOrganisationProps) {


  const [organisationID, setOrganisationID] = useState(props.organisationID);
  const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);

  const { data: organisations, isLoading: organisationsLoading } = useOrganisationsQuery("");

  useEffect(() => {
    setOrganisationID(props.organisationID);
  }, [props.organisationID]);

  function flattenedSelectOrganisation(data: any[]): any[] {

    const nodes = _.cloneDeep(data);
    const flattenTree = (nodes: any) => (
      nodes
        .flatMap((node: any) => [node, ...flattenTree(node.children || [])])
        .flat()
    );
    return flattenTree(nodes);
  }

  function treeViewRender() {
    return (
      <SelectOrganisationTree Organisations={organisations || []} onValueChange={SelectOrganisation_ValueChange} organisationID={organisationID} />
    );
  }

  function SelectOrganisation_ValueChange(val: number) {
    props.SelectOrganisation_ValueChange(val);

    setOrganisationID(val);
    setIsTreeBoxOpened(false);
  }

  function onTreeBoxOpened(e: any) {
    if (e.name === 'opened') {
      setIsTreeBoxOpened(e.value);
    }
  }

  if (organisationsLoading || !organisations) return null;

  if (organisations.length === 0) {
    return null;
  }

  const dropdownOrgs = flattenedSelectOrganisation(organisations);

  if (dropdownOrgs.length === 1) {
    return <>{organisations[0].organisationName}</>;
  }

  return (
    <DropDownBox
      value={organisationID}
      opened={isTreeBoxOpened}
      placeholder="Select Organisation"
      valueExpr="organisationID"
      displayExpr="organisationName"
      showClearButton={false}
      items={dropdownOrgs}
      onOptionChanged={onTreeBoxOpened}
      contentRender={treeViewRender}
      onOpened={onTreeBoxOpened}
      width={props.width || 150}
    >
      <DropDownOptions width={300}></DropDownOptions>
    </DropDownBox>
  );
}
