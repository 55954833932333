/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import "./home.scss";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { loginRequest } from "./../../authConfig";
import { HomeIcons } from '../../pages/home/home-icons';
import { Button } from "devextreme-react";



export function HomePage() {

  const { instance } = useMsal();

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <HomeIcons />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div className="content-main">
          <div className="home-unauthenticated">
            <h2>Welcome to MeterVue 7</h2>
          <Button onClick={() => instance.loginRedirect(loginRequest)}
          className="button-info"
          text="Login"
          icon="fas fa-sign-in-alt"
          rtlEnabled={true} />
            <p>Your MeterVue 7 account uses different login details to your original MeterVue account.</p>
            <p>To access your data in MeterVue 7, you must first <button onClick={(e) => instance.loginRedirect(loginRequest)} className="link">Login</button></p>
            <p>
            You can also <button onClick={(e) => instance.loginRedirect(loginRequest)} className="link">Register an account</button> or <a href="https://ipsauth.b2clogin.com/ipsauth.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_metervue_reset_password&client_id=5a794e7c-6070-4f8f-8a98-6145aec17b2e&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fv7.metervue.net%2Floginredirect&scope=openid&response_type=id_token&prompt=login">Reset your password</a>
            </p>
          </div>
        </div>
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
}
