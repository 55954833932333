
import './css/rsuite-picker.css';
import 'react-reflex/styles.css'

import './css/layout.scss';
import './app.scss';
import './css/general.scss';

import { msalConfig } from './authConfig';
import { MsalProvider, useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { locale } from "devextreme/localization";
import LoadPanel from 'devextreme-react/load-panel';
import { Header } from './components/header/header'
import { HomePage } from './pages/home/home';
import { LogoutPage } from './pages/logout/logout';
import React from 'react';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from './AppInsights';
import Page from './page';

export const FallBack = () => <div className="loading-overlay"><div className="loader"></div></div>;

function App() {

  locale(navigator.language);
  const msalInstance = new PublicClientApplication(msalConfig);
  const { inProgress } = useMsal();

  const MetersPage = React.lazy(() => import('./pages/meters/meters-page'));
  const Meter = React.lazy(() => import('./pages/meters/meter'));
  const MeterGroup = React.lazy(() => import('./pages/meters/meter-group'));
  const MeterSite = React.lazy(() => import('./pages/meters/meter-site'));
  const ChartPage = React.lazy(() => import('./pages/chart/chart-page'));
  const DataPage = React.lazy(() => import('./pages/data-management/data-page'));
  const DataSourcesPage = React.lazy(() => import('./pages/data-management/data-sources-page'));
  const MissingDataPage = React.lazy(() => import('./pages/missing-data/missing-data'));
  const DataCorrectionPage = React.lazy(() => import('./pages/data-correction/data-correction-page'));
  const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard'));
  const AlarmsPage = React.lazy(() => import('./pages/alarms/alarms-page'));
  const AlarmsAdminPage = React.lazy(() => import('./pages/alarms/alarms-admin-page'));
  const AlarmsEditSetting = React.lazy(() => import('./pages/alarms/alarms-edit-setting'));
  const AlarmsNewSetting = React.lazy(() => import('./pages/alarms/alarms-new-setting'));
  const AccountPage = React.lazy(() => import('./pages/account/account'));
  const DeBsPage = React.lazy(() => import('./pages/debs/debs-page'));
  const SecurityPage = React.lazy(() => import('./pages/security/security-page'));
  const SecurityEditUser = React.lazy(() => import('./pages/security/security-edit-user'));
  const SecurityNewUser = React.lazy(() => import('./pages/security/security-new-user'));
  const SecurityEditSecurityGroup = React.lazy(() => import('./pages/security/security-edit-security-group'));
  const SecurityNewSecurityGroup = React.lazy(() => import('./pages/security/security-new-security-group'));
  const SecurityEditOrganisation = React.lazy(() => import('./pages/security/security-edit-organisation'));
  const SecurityNewOrganisation = React.lazy(() => import('./pages/security/security-new-organisation'));
  const SubscriptionPage = React.lazy(() => import('./pages/subscription/subscription-page'));
  const LicensesPage = React.lazy(() => import('./pages/subscription/licenses'));
  const LicenseAdmin = React.lazy(() => import('./pages/subscription/license-admin'));
  const HelpPage = React.lazy(() => import('./pages/help/help-page'));  
  const HelpContent = React.lazy(() => import('./pages/help/help-content'));


  const LoginRedirectPage = React.lazy(() => import('./pages/account/login-redirect'));

  return (
    <BrowserRouter>
      <AppInsightsContext.Provider value={reactPlugin}>
        <MsalProvider instance={msalInstance}>
          <div className={`mv-body`}>
            <Header />
            <div className="mv-body-content">
              <AuthenticatedTemplate>
                <Routes>
                  <Route path="/" element={<Page title="Home"><HomePage /></Page>} />
                  <Route path="/loginredirect" element={<React.Suspense fallback={<FallBack />}><Page title="Login"><LoginRedirectPage /></Page></React.Suspense>} />
                  <Route path="/dashboard" element={<React.Suspense fallback={<FallBack />}><Page title="Dashboard"><Dashboard /></Page></React.Suspense>} />
                  <Route path="/alarms" element={<React.Suspense fallback={<FallBack />}><Page title="Alarms"><AlarmsPage /></Page></React.Suspense>} />
                  <Route path="/alarms/admin" element={<React.Suspense fallback={<FallBack />}><Page title="Alarms admin"><AlarmsAdminPage /></Page></React.Suspense>}>
                    <Route path=":alarmSettingIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Alarms admin"><AlarmsEditSetting /></Page></React.Suspense>} />
                    <Route path="new" element={<React.Suspense fallback={<FallBack />}><Page title="New Alarm"><AlarmsNewSetting /></Page></React.Suspense>} />
                  </Route>
                  <Route path="/security" element={<React.Suspense fallback={<FallBack />}><Page title="Security"><SecurityPage /></Page></React.Suspense>} />
                  <Route path="/security/users" element={<React.Suspense fallback={<FallBack />}><Page title="Users"><SecurityPage /></Page></React.Suspense>}>
                    <Route path=":userIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Edit user"><SecurityEditUser /></Page></React.Suspense>} />
                    <Route path="new" element={<React.Suspense fallback={<FallBack />}><Page title="New user"><SecurityNewUser /></Page></React.Suspense>} />
                  </Route>
                  <Route path="/security/groups" element={<React.Suspense fallback={<FallBack />}><Page title="Security"><SecurityPage /></Page></React.Suspense>}>
                    <Route path=":securitygroupIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Edit security group"><SecurityEditSecurityGroup /></Page></React.Suspense>} />
                    <Route path="new" element={<React.Suspense fallback={<FallBack />}><Page title="New security group"><SecurityNewSecurityGroup /></Page></React.Suspense>} />
                  </Route>
                  <Route path="/security/organisations" element={<React.Suspense fallback={<FallBack />}><Page title="Security"><SecurityPage /></Page></React.Suspense>}>
                    <Route path=":organisationIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Edit organisation"><SecurityEditOrganisation /></Page></React.Suspense>} />
                    <Route path="new" element={<React.Suspense fallback={<FallBack />}><Page title="New organisation"><SecurityNewOrganisation /></Page></React.Suspense>} />
                  </Route>
                  <Route path="/account" element={<React.Suspense fallback={<FallBack />}><Page title="Account"><AccountPage /></Page></React.Suspense>} />
                  <Route path="/meters" element={<React.Suspense fallback={<FallBack />}><Page title="Meters"><MetersPage /></Page></React.Suspense>}>
                    <Route path="meter/:nodeIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Meter"><Meter /></Page></React.Suspense>}>
                      <Route path=":tabName" element={<React.Suspense fallback={<FallBack />}><Page title="Meter"><Meter /></Page></React.Suspense>} />
                      <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="Meter"><Meter /></Page></React.Suspense>} />
                    </Route>
                    <Route path="group/:nodeIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Meter group"><MeterGroup /></Page></React.Suspense>}>
                      <Route path=":tabName" element={<React.Suspense fallback={<FallBack />}><Page title="Meter group"><MeterGroup /></Page></React.Suspense>} />
                      <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="Meter group"><MeterGroup /></Page></React.Suspense>} />
                    </Route>
                    <Route path="site/:nodeIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Meter site"><MeterSite /></Page></React.Suspense>}>
                      <Route path=":tabName" element={<React.Suspense fallback={<FallBack />}><Page title="Meter site"><MeterSite /></Page></React.Suspense>} />
                      <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="Meter site"><MeterSite /></Page></React.Suspense>} />
                    </Route>
                  </Route>
                  <Route path="/chart" element={<React.Suspense fallback={<FallBack />}><Page title="Chart"><ChartPage /></Page></React.Suspense>}>
                    <Route path=":alarmIDParam" element={<React.Suspense fallback={<FallBack />}><Page title="Chart"><ChartPage /></Page></React.Suspense>} />
                    <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="Chart"><ChartPage /></Page></React.Suspense>} />
                  </Route>
                  <Route path="/data" element={<React.Suspense fallback={<FallBack />}><Page title="Data"><DataPage /></Page></React.Suspense>} />
                  <Route path="/data/sources" element={<React.Suspense fallback={<FallBack />}><Page title="Data Sources"><DataSourcesPage /></Page></React.Suspense>} />
                  <Route path="/data/missing" element={<React.Suspense fallback={<FallBack />}><Page title="Missing Data"><MissingDataPage /></Page></React.Suspense>} />
                  <Route path="/data/corrections" element={<React.Suspense fallback={<FallBack />}><Page title="Data correction"><DataCorrectionPage /></Page></React.Suspense>} />
                  <Route path="/debs" element={<React.Suspense fallback={<FallBack />}><Page title="DeBs"><DeBsPage /></Page></React.Suspense>}>
                    <Route path=":tabName" element={<React.Suspense fallback={<FallBack />}><Page title="DeBs"><DeBsPage /></Page></React.Suspense>} />
                    <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="DeBs"><DeBsPage /></Page></React.Suspense>} />
                  </Route>
                  <Route path="/subscription" element={<React.Suspense fallback={<FallBack />}><Page title="Subscription"><SubscriptionPage /></Page></React.Suspense>}>
                    <Route path="license" element={<React.Suspense fallback={<FallBack />}><Page title="License"><LicensesPage /></Page></React.Suspense>} />
                    <Route path="license/admin" element={<React.Suspense fallback={<FallBack />}><Page title="License Admin"><LicenseAdmin /></Page></React.Suspense>} />
                    <Route path="license/:licenseGUID" element={<React.Suspense fallback={<FallBack />}><Page title="License"><LicensesPage /></Page></React.Suspense>}>
                      <Route path=":tabName" element={<React.Suspense fallback={<FallBack />}><Page title="License"><LicensesPage /></Page></React.Suspense>} />
                      <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="License"><LicensesPage /></Page></React.Suspense>} />
                    </Route>
                  </Route>
                  <Route path="/help" element={<React.Suspense fallback={<FallBack />}><Page title="Help"><HelpPage /></Page></React.Suspense>}>
                      <Route path=":page" element={<React.Suspense fallback={<FallBack />}><Page title="Help"><HelpContent /></Page></React.Suspense>} />
                      <Route path="" element={<React.Suspense fallback={<FallBack />}><Page title="Help"><HelpContent /></Page></React.Suspense>} />
                  </Route>
                  <Route path="*" element={<Page title="Home"><HomePage /></Page>} />
                </Routes>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                {inProgress === "login" ? <LoadPanel /> :
                  <Routes>
                    <Route path="/" element={<Page title="Home"><HomePage /></Page>} />
                    <Route path="/logout" element={<Page title="Logout"><LogoutPage /></Page>} />
                    <Route path="*" element={<Page title="Home"><HomePage /></Page>} />
                  </Routes>
                }
              </UnauthenticatedTemplate>
            </div>
          </div>
        </MsalProvider>
      </AppInsightsContext.Provider>
    </BrowserRouter>
  );
}

export default App;
