import { useEffect } from "react";

import { useMsal } from "@azure/msal-react";
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const Page = (props: any) => {



  useEffect(() => {
    document.title = props.title;
  }, [props.title]);


  const { accounts } = useMsal();
  const appInsightsContext = useAppInsightsContext();
  var telemetryInitializer = (envelope: any) => {
    //Add a custom property
    envelope.tags['ai.user.authUserId'] = accounts[0]?.username;
    envelope.tags['ai.user.id'] = accounts[0]?.username;
  };
  appInsightsContext.getAppInsights().addTelemetryInitializer(telemetryInitializer);

  return props.children;
};

export default Page;