import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
const browserHistory = createBrowserHistory({});
var reactPlugin = new ReactPlugin();

var appInsights = new ApplicationInsights({
  config: {
    connectionString: 'InstrumentationKey=99997e36-2258-4670-9284-a75112434629;IngestionEndpoint=https://northeurope-0.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    autoTrackPageVisitTime: true,
  }
});

appInsights.loadAppInsights();
appInsights.trackPageView();


export { reactPlugin };