import { configureStore, ThunkAction, Action, MiddlewareAPI, isRejectedWithValue, Middleware, } from '@reduxjs/toolkit';
import { metervueApi } from '../services/metervueapi';


export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      console.warn('Error: ', action.error?.message);
    }

    return next(action)
  }

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [metervueApi.reducerPath]: metervueApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(metervueApi.middleware)
      .concat(rtkQueryErrorLogger),
})


export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
