
let BaseUrl = "https://api.metervue.net/";

if (window.location.host.indexOf('-staging') > -1) {
  BaseUrl ="https://api7-staging.azurewebsites.net/";
}
if (window.location.host.indexOf('localhost') > -1) {
  BaseUrl = "https://localhost:44348/";
}

const objectMap = (obj: any, fn: Function) =>
  Object.fromEntries(
    Object.entries(obj).map(
      ([k, v], i) => [k, fn(v, k, i)]
    )
  )

const endpoints = {
  alarms: "Alarms/List",
  alarm: "Alarms",
  chartdata: "Chart/Data",
  meterdata: "Data/MeterData",
  datachanneldata: "Data/DataChannelData",
  alarmfilters: "AlarmFilters/UserList",
  alarmupdatefilters: "AlarmFilters/UpdateFilters",
  alarmupdatestatus: "Alarms/Status/Update",
  alarmstatushistory: "Alarms/Status/History",
  alarmdataalarms:  "Alarms/DataAlarms",
  metertree:  "MeterTree", 
  meterdetails:  "Meters/Details", 
  meterupdatedetails:  "Meter/UpdateDetails", 
  meterdatachannels:  "Meter/DataChannels",
  useraccount: "UserAccount",  
  useraccountsetorganisation: "UserAccount/SetCurrentOrganisation",
  datasources: "DataSources",   
  metercategories: "MeterCategories", 
  addmetercategory: "Meter/AddCategory",  
  addexpecteddata: "Meter/AddExpectedData",
  deletemetercategory: "Meter/DeleteCategory",  
  deleteexpecteddata: "Meter/DeleteExpectedData",
  datatypes: "DataTypes",
  organisations: "UserAccount/ListOrganisations",
  addmeter: "Meters/Add",
  addmetergroup: "MeterGroups/Add",
  metergroupdetails: "MeterGroups/Details",
  metersitedetails: "MeterSites/Details",
  updatemetersitedetails: "MeterSites/Update",
  addmetersite: "MeterSites/Add",
  metertreenodecategories: "MeterTree/NodeCategories",
  datasourcesupload: "DataSources/Upload",
}

const eps = objectMap(endpoints, (p: string) => BaseUrl + p )

const config = { endpoints: eps };

export default config;