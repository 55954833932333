import "./home.scss";
import { NavLink } from "react-router-dom";
import { useUserAccountQuery, useUserPermissionsQuery } from "../../services/metervueapi";
import { INavigationItem } from "../../interfaces/IUser";
import { useEffect, useState } from "react";
import { FallBack } from "../../App";

export function HomeIcons() {

    const { data: user} = useUserAccountQuery("");
    const { data: securityPermissions } = useUserPermissionsQuery("");
    const [items, setItems] = useState([] as INavigationItem[]);

    useEffect(() => {
        if (!securityPermissions || !user) return;

        let items = user.navigation?.filter((item) => item.path !== '/') || [];

        if (!securityPermissions.viewChart) {
            items = items.filter((item) => item.path !== '/chart');
        }
        if (!securityPermissions.viewMeters) {
            items = items.filter((item) => item.path !== '/meters');
        }
        if (!securityPermissions.viewAlarms) {
            items = items.filter((item) => item.path !== '/alarms');
        }
        if (!securityPermissions.viewRequestData && !securityPermissions.viewDataSources && !securityPermissions.correctData) {
            items = items.filter((item) => item.path !== '/data');
        }
        if (!securityPermissions.createUsers && !securityPermissions.createOrganisations) {
            items = items.filter((item) => item.path !== '/security');
        }
        if (user.currentOrganisationID !== 61) {
            items = items.filter((item) => item.path !== '/debs');
        }       
       
        setItems(items);

    }, [securityPermissions, user]);


    if (!user) return null;
  if (!securityPermissions) return <FallBack />

    return <div className="homeMenu">{items.map((item) =>
        <NavLink
            to={item.path}
            key={item.path}
            className={item.cssClass}
        >{item.text}</NavLink>
    )}</div>;
}