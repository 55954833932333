import './user-panel.scss';
import SelectOrganisation from "../select-organisation/select-organisation";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useUserAccountQuery, useUserAccountSetCurrentOrganisationMutation } from '../../services/metervueapi';
import { Button } from 'devextreme-react';
import { useLocation, useNavigate } from 'react-router';

export default function UserPanel() {

  const { instance } = useMsal();

  return <>
    <UnauthenticatedTemplate>
      <div className="mv-menu-button">
        <Button onClick={() => instance.loginRedirect(loginRequest)}
          className="button-info"
          text="Login"
          icon="fas fa-sign-in-alt"
          rtlEnabled={true} />
      </div>
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <AuthTemplate />
    </AuthenticatedTemplate>
  </>
}


function AuthTemplate() {

  const { instance } = useMsal();
  const { data: user } = useUserAccountQuery("");
  const [updateUser] = useUserAccountSetCurrentOrganisationMutation();
  let location = useLocation();
  const  navigate = useNavigate();
  
  function SelectOrganisation_ValueChange(val: number) {
    if (!user) return;

    var newUser = { ...user, currentOrganisationID: val };
    updateUser(newUser);
    navigate(location.pathname.split('/').length > 1 ? location.pathname.split('/')[1] : '/');
  }

  const mappedPaths: Record<string, string> = {
    "alarms": "/alarms/",
    "meters": "/meters/",
    "security": "/security/",
    "account": "/managing-your-account/",    
    "chart": "/charting/",    
    "data": "/data-management/",
  };  
  let helpPage = mappedPaths[location.pathname.replace("/","")];

  if (!helpPage) 
    helpPage = "";
  
  if (!user) return null;

  return <div className={'user-panel'}>
    
    <div className={'user-panel-section-user'}>
    <div className={'user-name'}>{user.emailAddress}</div>
    </div>
    <div className={'user-panel-section-logout'}>
    <a href={"https://help.metervue.net" + helpPage} target="metervuehelp" className="help-icon">Help</a>
      <button onClick={() => instance.logout()} className="user-panel-logout">Log out</button> 
    </div>
    <div className={'user-panel-section-org'}>
      <SelectOrganisation organisationID={user.currentOrganisationID} width={250} SelectOrganisation_ValueChange={SelectOrganisation_ValueChange} />
    </div>

  </div>
}