import './../meter-tree/meter-tree.scss';
import { useRef, useState } from 'react';
import TreeList, { Column, SearchPanel, Selection, Scrolling } from 'devextreme-react/tree-list';
import { Template } from 'devextreme-react/core/template';

type SelectOrganisationProps = { onValueChange: (nodeID: number) => void, organisationID?: number, Organisations: any[] };

  export default function SelectOrganisationTree(props: SelectOrganisationProps) {

  const treeListRef = useRef<TreeList<null,null>>(null);
  const [organisationID, setOrganisationID] = useState(props.organisationID);

  function treeView_itemSelectionChanged(e: any) {
    const nodeID: number = e.selectedRowKeys[0];
    setOrganisationID(nodeID);

    if (props.organisationID !== nodeID) {
      props.onValueChange(nodeID);
    }
    
    expandNode(nodeID);
  }

  function expandNode(NodeId: number) {
    if (!props.Organisations.length) return;

    var node: any = searchSelectOrganisation(props.Organisations[0], NodeId);

    if (!node) return;

    treeListRef.current?.instance.expandRow(node.organisationID);
    if (node.p) {
      expandNode(node.p);
    }
  }

  function searchSelectOrganisation(startNode: any, NodeId: number): any {
    if (startNode.organisationID === NodeId) {
      return startNode;
    } else if (startNode.c && startNode.children.length) {
      let result = null;
      for (let i = 0; result === null && i < startNode.children.length; i++) {
        result = searchSelectOrganisation(startNode.children[i], NodeId);
      }
      return result;
    }
    return null;
  }

  function treeView_onRowClick(e: any) {
    if (!props.Organisations.length) return;

    e.component.selectRows([organisationID]);
    e.component.expandRow(props.Organisations[0]?.organisationID);
  }

  function treeView_Node_Template(options: any) {
    return <span className="tno">{options.data.organisationName}</span>
  }

    return (
      <TreeList
        ref={treeListRef}
        dataSource={props.Organisations}
        itemsExpr="children"
        dataStructure="tree"
        keyExpr="organisationID"
        width={300}
        height={400}
        showColumnHeaders={false}
        onSelectionChanged={treeView_itemSelectionChanged}
        onContentReady={treeView_onRowClick}
      >
        <Scrolling mode="standard" />
        <Selection mode="single" />
        <SearchPanel visible={true} width={200} />
        <Column dataField="organisationName" dataType="string" cellTemplate="nodeTemplate" />
        <Template name="nodeTemplate" render={treeView_Node_Template} />
        <Column dataField="s" dataType="string" visible={false} />
      </TreeList>
    );
  }
